@import "./fonts.css";
@import "./variables.css";

div {
  font-family: "Helvetica", Arial, sans-serif;
}

h1,
h2 {
  color: black;
}