.project-container {
  margin-bottom: 50px;
  width: 560px !important;
  height: 560px !important;
  position: relative;
  padding: 0 0 0 0 !important;
}

.project-container:hover {
  cursor: zoom-in;
}

.project-rmg {
  margin-right: 10px;
}

.project-lmg {
  margin-left: 10px;
}

.project-title {
  color: white;
  font-size: var(--default-font-size);
  padding-top: 10px;
}

.project-title-div {
  position: absolute;
  bottom: 0;
  height: 15%;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.projects-list-row {
  display: flex;
  justify-content: space-around;
}

.project-img {
  border-radius: 10px;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.projects-title {
  margin-top: 100px !important;
  margin-bottom: 100px;
  font-size: var(--title-font-size);
  text-align: center;
}

@media (max-width: 1200px) {
  .project-container {
    margin-bottom: 30px;
    width: 460px !important;
    height: 460px !important;
  }
}

@media (max-width: 992px) {
  .fslightbox-container .fslightbox-toolbar-button[title="Enter fullscreen"] {
    display: none;
  }

  .project-rmg {
    margin-right: 0;
  }

  .project-lmg {
    margin-left: 0;
  }

  .projects-title {
    margin-top: 80px !important;
    margin-bottom: 80px;
  }

  .project-container {
    margin-bottom: 40px;
    width: 500px !important;
    height: 500px !important;
  }
}

@media (max-width: 768px) {
  .project-container {
    margin-bottom: 40px;
    width: 450px !important;
    height: 450px !important;
  }
}

@media (max-width: 576px) {
  .project-container {
    width: 300px !important;
    height: 300px !important;
  }

  .projects-title {
    margin-top: 60px !important;
    margin-bottom: 60px;
  }
}