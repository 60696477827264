.img-background {
  position: absolute;
  width: 100%;
  height: 850px;
  z-index: -1;
  top: -100px;
  object-fit: cover;
  filter: brightness(50%);
  /*blur(2px)*/
  padding-bottom: 100px;
}

.head-container {
  position: relative;
  background-image: url("../../images/headerbg.jpg");
  z-index: 1;
  background-size: cover;
  background-attachment: scroll;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 20px;
  padding-bottom: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding-top: 260px;
  margin-top: -240px; */
}

.limiter {
  max-width: 1150px;
  width: 100%;
}

.head-container * {
  position: relative;
  z-index: 2;
  /* Установите z-index выше, чем у .head-container и ::before */
}

/* Добавьте этот псевдоэлемент */
.head-container::before {
  content: "";
  /* Необходимо для отображения псевдоэлемента */
  position: absolute;
  /* Позиционирование псевдоэлемента */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Черный цвет с 50% прозрачностью */
  z-index: 0;
  /* Позиционирование псевдоэлемента под содержимым контейнера */
}

h1,
h2,
h3,
p {
  color: white;
}

.separator-div {
  /* width: 100px; */
  height: 230px;
  /* background-color: black; */
}

/* @media (max-width: 1400px) {
  .separator-div {
    background-color: aqua;
  }
} */

@media (max-width: 1200px) {
  .separator-div {
    /* background-color: green; */
    height: 200px;
  }
}

@media (max-width: 992px) {
  .separator-div {
    /* background-color: yellow; */
    height: 160px;
  }

  .limiter {
    width: auto;
  }
}

@media (max-width: 768px) {
  .separator-div {
    /* background-color: orange; */
    height: 130px;
  }
}

@media (max-width: 576px) {
  .separator-div {
    /* background-color: red; */
    height: 70px;
  }

  .head-container {
    background-position: left;
  }
}