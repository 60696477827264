.footer-container {
    position: relative;
    background-image: url("../../images/footerbg.png");
    z-index: 1;
    background-size: cover;
    background-attachment: scroll;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
}

.footer-subcontainer {
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-container * {
    position: relative;
    z-index: 2;
}

.footer-title {
    font-size: var(--title-font-size);
    color: #ffffff;
    line-height: 1.1;
    font-weight: 300;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    opacity: 0;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    transform: translateY(-80px);
    text-align: center;
}

.show {
    opacity: 1;
    transform: translateY(0);
}

.footer-subtitle {
    font-size: var(--default-font-size);
    text-align: center;
    margin-top: 30px !important;
    font-weight: 300;
    margin-bottom: 40px;
}

.footer-row {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.footer-animated-arrow {
    right: -180px;
    top: -20px;
}

.br-visible {
    display: none;
}

@media (max-width: 992px) {
    .br-visible {
        display: block;
    }

    .footer-animated-arrow {
        right: -30px;
        top: -120px;
    }

    .footer-subcontainer {
        height: 500px;
    }

    .footer-subtitle {
        margin-bottom: 30px;
    }

}

@media (max-width: 768px) {
    .footer-animated-arrow {
        right: -30px;
        top: -110px;
    }
}

@media (max-width: 576px) {
    .footer-animated-arrow {
        right: -7px;
        top: -110px;
    }

    .footer-title {
        font-size: 26px;
    }
}