.advantages-title-col {
  display: flex;
  justify-content: center;
  margin-bottom: 120px;
}

.advantages-title {
  font-size: var(--title-font-size);
  color: black !important;
}

.advantages-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.advantage-col {
  display: flex;
  align-items: center;
}

.advantage-col-right {
  justify-content: flex-end;
  /* padding-left: 0 !important; */
}

.advantage-col-left {
  justify-content: flex-start;
  /* padding-right: 0 !important; */
}

.advantage-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translateX(-50%) translateY(-45%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.advantage-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.advantage-description {
  font-size: 20px;
  font-weight: 300;
  width: 90%;
  text-align: center;
  margin-bottom: 0;
}

.advantage-container {
  width: 550px;
  height: 240px;
  border: 1px solid #5e703d;
  border-radius: 10px;
  position: relative;
  margin-bottom: 90px;
}

.sheet-icon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0.9);
}

@media (max-width: 1400px) {
  .advantage-container {
    width: 530px;
  }

  .advantage-description {
    width: 92%;
  }
}

@media (max-width: 1200px) {
  .advantage-container {
    width: 450px;
    height: 200px;
  }

  .advantage-title {
    font-size: 22px;
  }

  .advantage-description {
    font-size: 18px;
    width: 98%;
  }

  .sheet-icon {
    transform: translateX(-50%) translateY(-50%) scale(0.7);
  }

  .advantages-title-col {
    margin-bottom: 100px;
  }
}

@media (max-width: 992px) {
  .advantage-container {
    width: 100%;
  }

  .advantage-title {
    margin-bottom: 12px;
  }
}

@media (max-width: 768px) {
  .advantage-title {
    font-size: 19px;
    margin-bottom: 10px;
  }

  .advantage-description {
    font-size: 15px;
    width: 100%;
  }

  .advantage-container {
    height: 160px;
    margin-bottom: 60px;
  }

  .sheet-icon {
    transform: translateX(-50%) translateY(-50%) scale(0.6);
  }
}

@media (max-width: 576px) {
  .advantage-title {
    font-size: 17px;
    margin-bottom: 10px;
  }

  .advantage-container {
    height: 160px;
    margin-bottom: 50px;
  }

  .advantage-description {
    font-size: 13px;
    width: 100%;
  }

  .advantage-content {
    width: 90%;
  }

  .sheet-icon {
    transform: translateX(-50%) translateY(-50%) scale(0.5);
  }

  .advantages-title-col {
    margin-bottom: 60px;
  }
}