:root {
  --container-width: 500px;
  --title-font-size: 48px;
  --default-font-size: 22px;
}

@media (max-width: 1200px) {
  :root {
    --container-width: 400px;
    --title-font-size: 40px;
    --default-font-size: 18px;
  }
}

@media (max-width: 992px) {
  :root {
    --container-width: 500px;
  }
}

@media (max-width: 768px) {
  :root {
    --container-width: 440px;
    --title-font-size: 34px;
    --default-font-size: 16px;
  }
}

@media (max-width: 576px) {
  :root {
    --container-width: 300px;
    --title-font-size: 24px;
    --default-font-size: 14px;
  }
}
@media (max-width: 350px) {
  :root {
    --title-font-size: 22px;
  }
}
