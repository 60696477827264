.service-title {
  font-size: var(--title-font-size);
  display: flex;
  margin-bottom: 80px;
}

.service-title-col {
  display: flex;
}

.service-bottom-line {
  border-bottom: 1px solid #5e703d;
}

.service-container {
  position: relative;
  height: 120px;
  width: 100%;
  border-top: 1px solid #5e703d;
}

.service-item {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.service-text {
  font-size: 22px;
  font-weight: 300;
  width: 46%;
  text-align: left;
  margin-right: 40px;
  margin-bottom: 0;
}

.service-index {
  padding-left: 40px;
  width: 50%;
  font-size: 30px;
  margin-bottom: 0;
}

@media (max-width: 1400px) {
  .service-text {
    width: 49%;
  }
}

@media (max-width: 1200px) {
  .service-text {
    font-size: 20px;
    width: 60%;
  }

  .service-index {
    width: 50%;
    font-size: 28px;
  }
}

@media (max-width: 992px) {
  .service-index {
    width: 30%;
    padding-left: 20px;
    font-size: 26px;
  }

  .service-text {
    font-size: 18px;
    width: 70%;
    margin-right: 20px;
  }
}

@media (max-width: 768px) {
  .service-index {
    font-size: 24px;
    width: 25%;
  }

  .service-text {
    font-size: 16px;
    width: 75%;
  }

  .service-title {
    margin-bottom: 60px;
  }
}

@media (max-width: 576px) {
  .service-index {
    font-size: 16px;
    width: 18%;
    padding-left: 10px;
  }

  .service-text {
    font-size: 13px;
    width: 90%;
    margin-right: 10px;
  }

  .service-container {
    height: 100px;
  }

  .service-title {
    margin-bottom: 50px;
  }
}