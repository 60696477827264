.animated-arrow {
  transform-origin: top right;
  animation: horizontalRocking 2s ease-in-out infinite;
  width: "100%";
  height: "100%";
  margin-left: 40px;
}

@keyframes horizontalRocking {

  0%,
  100% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(8deg);
  }
}

.svg-arrow {
  width: 142px;
  height: auto;
}

@media (max-width: 992px) {
  .svg-arrow {
    width: 120px;
  }

  .animated-arrow {
    margin-left: 10px;
  }
}

@media (max-width: 992px) {
  .svg-arrow {
    width: 120px;
  }

  .footer-animated-arrow {
    animation: verticalRocking 2s ease-in-out infinite;
  }

  /* .animated-arrow {
    margin-left: 30px;
  } */
}

@media (max-width: 768px) {
  .animated-arrow {
    margin-left: 30px;
  }
}

@media (max-width: 576px) {
  .header-animated-arrow {
    margin-top: -150px;
    margin-left: -100px;
    animation: verticalRocking 2s ease-in-out infinite;
    position: absolute;
  }
}

@keyframes verticalRocking {

  0%,
  100% {
    transform: rotate(-45deg);
  }

  50% {
    transform: rotate(-48deg);
  }
}