.custom-arrow {
    width: 24px;
    height: 24px;
}

@media (max-width: 768px) {
    .custom-arrow {
        width: 18px;
        height: 18px;
    }
}

@media (max-width: 576px) {
    .custom-arrow {
        width: 15px;
        height: 15px;
    }
}