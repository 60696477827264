.custom-button {
  background-color: #db6630;
  color: #fff;
  padding: 15px 50px;
  border: none;
  line-height: 1.55;
  text-align: center;
  margin-top: 50px;
  font-size: 20px;
  font-weight: 300;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
  transition-property: background-color, color, border-color;
  transition-duration: 0.2s, 0.2s, 0.2s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
  transition-delay: 0s, 0s, 0s;
}

.custom-button:hover {
  background-color: #5e703d;
}

.disabled-custom-button {
  opacity: 0.5 !important;
  pointer-events: none;
}

@media (max-width: 992px) {
  .custom-button {
    font-size: 18px;
    padding: 12px 45px;
  }
}

@media (max-width: 768px) {
  .custom-button {
    padding: 10px 35px;
    font-size: 14px;
  }
}
