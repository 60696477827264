.footer-additional-container {
    background-color: black;
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.white-line {
    height: 2px;
    width: 90%;
    background-color: white;
}

.info-container {
    width: 600px;
    display: flex;
    justify-content: space-between;
    color: white;
    margin-bottom: 20px;
}

.info-text {
    color: white;
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
    margin-bottom: 20px;
    text-decoration: none;
}

.confidentiality {
    color: white;
    font-size: 12px;
    font-weight: 300;
    margin-top: 25px;
}

.confidentiality:hover {
    cursor: pointer;
}

@media (max-width: 992px) {
    .info-text {
        font-size: 16px;
    }

    .white-line {
        width: 100%;
    }

    .info-container {
        width: 500px;
    }
}

@media (max-width: 768px) {
    .confidentiality-text {
        font-size: var(--default-font-size);
    }

    .info-text {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .info-container {
        width: 350px;
    }

    .footer-additional-container {
        height: 170px;
    }
}

@media (max-width: 576px) {
    .info-container {
        width: 250px;
        margin-bottom: 10px;
    }

    .info-text {
        font-size: 10px;
        margin-bottom: 0px;
    }

    .confidentiality {
        font-size: 8px;
        margin-top: 17px;
    }

    .footer-additional-container {
        height: 140px;
    }
}