@font-face {
  font-family: "Helvetica";
  src: url("fonts/helvetica_regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  src: url("fonts/helvetica_bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  src: url("fonts/helvetica_boldoblique.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica";
  src: url("fonts/helvetica_oblique.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica";
  src: url("fonts/helvetica_light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  src: url("fonts/helvetica_lightoblique.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}
