.header-title {
  color: #ffffff;
  font-size: 68px;
  line-height: 1.1;
  font-weight: 300;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: translateY(-80px);
}

.show {
  opacity: 1;
  transform: translateY(0);
}

.header-button {
  width: 310px;
}

.feedback-header-visible {
  display: flex !important;
}

.feedback-title-visible {
  display: none !important;
}

.popup-custom-button {
  margin-top: 0 !important;
}

.header-subtitle {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
}

.header-container {
  padding-top: 60px;
  /* max-width: 1200px !important; */
}

.arrow-for-button {
  position: absolute;
  bottom: -40px;
  left: 10px;
}

.checkbox-label {
  font-size: 0.875rem;
}

.modal-title-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.header-icons {
  width: 31px;
  height: 31px;
  margin-left: 20px;
}

@media (max-width: 992px) {
  .header-title {
    font-size: 40px;
    line-height: 46px;
  }

  .header-subtitle {
    font-size: 20px;
    line-height: 30px;
  }

  .col-title {
    display: flex;
    align-items: center;
  }

  .header-container {
    width: var(--container-width) !important;
  }

  .header-button {
    width: 280px;
  }
}

@media (max-width: 768px) {
  .header-title {
    font-size: 33px;
    line-height: 39px;
  }

  .header-subtitle {
    font-size: 18px;
    line-height: 30px;
  }

  .header-button {
    width: 215px;
  }
}

@media (max-width: 576px) {
  .header-container {
    margin: auto;
  }

  .header-button {
    width: 208px;
  }

  .modal-footer {
    padding: 6px !important;
    font-size: 14px;
  }

  .feedback-header-visible {
    display: none !important;
  }

  .feedback-title-visible {
    display: flex !important;
  }
}