.my-navbar {
  position: relative;
}

/* .navbar-container {
  max-width: 1200px !important;
} */

.logo {
  display: block;
  max-width: 100%;
  height: auto;
}

.nav-item {
  margin-left: 20px;
}

.my-navbar .nav-link {
  color: white;
  font-size: 1.3rem;
  font-weight: 350;
}

@media (max-width: 992px) {
  .logo {
    width: 80%;
  }
  .my-navbar {
    width: var(--container-width) !important;
  }
}

@media (max-width: 768px) {
  .logo {
    width: 60%;
  }
}
