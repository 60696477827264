@import "../../styles/variables.css";

.type-animation {
  color: black;
  font-size: 30px;
  font-weight: 300;
  display: inline-block;
  white-space: pre-line;
}

.title {
  color: #000000;
  font-size: var(--title-font-size);
  line-height: 1.5;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  margin-top: -20px;
}

.description-container {
  height: 445px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 30px;
}

.body-title {
  width: 500px;
  height: 445px;
}

.hr-separator {
  margin-top: 20px;
}

.title-col {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 120px;
}

.type-animation-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100px;
}

.description {
  font-size: var(--default-font-size);
  font-weight: 300;
  line-height: 1.4;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: translateY(-60px);
}

.show {
  opacity: 1;
  transform: translateY(0);
}

.hr-separator-col {
  display: flex;
  align-content: center;
  justify-content: center;
}

@media (max-width: 1400px) {
  .description-container {
    margin-left: 0px;
  }
}

@media (max-width: 1200px) {
  .lyrata-description {
    font-size: 30px;
  }
  .type-animation {
    font-size: 26px;
  }
  /* .title {
    font-size: 40px;
  } */
  .body-title {
    width: var(--container-width);
    height: 365px;
  }
  .description-container {
    height: 365px;
  }
  .hr-separator {
    margin-top: 15px;
    padding-right: 10px;
  }
  .title-col {
    margin-bottom: 100px;
  }
}

@media (max-width: 992px) {
  .title-col {
    justify-content: center;
    order: 1;
    margin-bottom: 40px;
  }
  .img-col {
    order: 2;
    margin-bottom: 60px;
  }
  .type-col {
    order: 3;
  }
  .description-col {
    order: 4;
  }
  .type-animation-container {
    width: var(--container-width);
    margin-bottom: 50px;
  }

  .title-col,
  .img-col,
  .type-col,
  .title-col,
  .description-col {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .description-container {
    width: var(--container-width);
  }
  .body-title {
    width: var(--container-width);
    height: 445px;
  }
}

@media (max-width: 768px) {
  .title-col {
    margin-bottom: 30px;
  }
  .body-title {
    height: 365px;
  }
  .description-container {
    height: 340px;
  }
  .type-animation-container {
    margin-bottom: 20px;
  }
  .type-animation {
    font-size: 22px;
  }
  .hr-separator {
    margin-top: 15px;
  }
  .img-col {
    margin-bottom: 40px;
  }
}

@media (max-width: 576px) {
  .body-title {
    height: auto;
  }
  .title-col {
    margin-bottom: 10px;
  }
  .description-container {
    height: 350px;
  }
  .type-animation-container {
    height: 70px;
  }
  .type-animation {
    font-size: 19px;
  }
}
