p {
  color: black;
}

.body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body-separator {
  width: 1px;
  height: 200px;
}

.body-limiter {
  max-width: 1150px;
}

@media (max-width: 1200px) {
  .body-separator {
    height: 130px;
  }
}

@media (max-width: 992px) {
  .body-container {
    width: var(--container-width) !important;
  }
  .body-separator {
    height: 100px;
  }
  .body-limiter {
    width: var(--container-width);
  }
}

@media (max-width: 768px) {
  .body-separator {
    height: 70px;
  }
}

@media (max-width: 576px) {
  .body-separator {
    height: 50px;
  }
}

@media (max-width: 400px) {
  .body-container {
    width: 280px !important;
  }
}
