.solutions-title {
    font-size: var(--title-font-size);
    margin-top: 50px;
    margin-bottom: 80px;
}

.solution-container {
    border: 1px solid #5e703d;
    border-radius: 10px;
    width: 100%;
    display: flex;
    overflow: hidden;
}

.solution-img {
    width: 55%;
    height: 550px;
    object-fit: cover;
    border-bottom-left-radius: 9px;
    border-top-left-radius: 9px;
}

.solution-detailed-container {
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.solution-detailed {
    height: 90%;
    width: 80%;
    position: relative;
}

.solution-text {
    font-size: 19px;
    font-weight: 300;
    margin-bottom: 0;
}

.solution-separator {
    margin-top: 25px;
}

.solution-plant-item {
    display: flex;
    align-items: center;
}

.solution-round {
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: black;
    margin-right: 10px;
}

.solution-price {
    position: absolute;
    bottom: 0;
}

.solution-pagination {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
}

.solution-arrow {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background-color: #5e703d;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.solution-arrow:hover {
    opacity: 0.7;
}

.right-arrow {
    transform: rotate(180deg);
    margin-left: 40px;
}

@media (max-width: 1200px) {
    .solution-text {
        font-size: 16px;
    }
}

@media (max-width: 992px) {
    .solution-container {
        flex-direction: column;
    }

    .solutions-title {
        margin-top: 30px;
        margin-bottom: 60px;
    }

    .solution-img {
        width: 100%;
        border-top-right-radius: 9px;
        border-bottom-left-radius: 0;
        height: 370px;
    }

    .solution-detailed-container {
        width: 100%;
        height: 500px;
    }

    .solution-container {
        width: 500px;
    }
}

@media (max-width: 768px) {
    .solution-text {
        font-size: 14px;
    }

    .solution-subtitle {
        font-size: 24px;
    }

    .solutions-title {
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .solution-detailed-container {
        height: 425px;
    }

    .solution-arrow {
        height: 25px;
        width: 25px;
    }

    .solution-container {
        width: 450px;
    }
}

@media (max-width: 576px) {
    .solution-text {
        font-size: 12px;
    }

    .solution-img {
        height: 260px;
    }

    .solutions-title {
        margin-top: 10px;
        margin-bottom: 40px;
    }

    .solution-subtitle {
        font-size: 19px;
    }

    .solution-detailed-container {
        height: 375px;
    }

    .solution-container {
        width: 300px;
    }

    .solution-detailed {
        width: 90%;
    }

    .right-arrow {
        margin-left: 20px;
    }

    .solution-round {
        width: 4px;
        height: 4px;
    }
}