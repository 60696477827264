.drawer-button {
  width: 60px;
  height: 60px;
  background-color: #5e703d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 666;
  right: 20px;
  top: 20px;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.drawer-button:hover {
  opacity: 0.9;
  cursor: pointer;
}

.lyrata-logo {
  width: 100%;
  height: auto;
}

.drawer-button-mg {
  margin-right: 17px;
}

.drawer-button:active {
  filter: brightness(80%);
}

.drawer {
  background-color: #5e703d !important;
  max-width: 80vw !important;
}

.btn-close {
  --bs-btn-close-color: white !important;
}

.offcanvas-link {
  color: white;
  font-size: 30px;
  text-decoration: none;
  margin: 0 auto;
}

.offcanvas-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.offcanvas-div {
  margin: 20px 0;
}

.offcanvas-container {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.drawer-icon-size {
  height: 40px;
  width: auto;
  cursor: pointer;
}

.drawer-icon-size:hover {
  opacity: 0.7;
}

.drawer-button-visible {
  opacity: 0.7;
}

@media (max-width: 992px) {
  .drawer-button {
    opacity: 0.7 !important;
  }
}

@media (max-width: 786px) {
  .offcanvas-link {
    font-size: 20px;
  }

  .drawer-icon-size {
    height: 30px;
  }

  .lyrata-logo {
    width: 80%;
  }

  .offcanvas-div {
    margin: 10px 0;
  }
}

@media (max-width: 576px) {
  .drawer-button {
    width: 50px;
    height: 50px;
    right: 10px;
    top: 10px;
  }
}