.hr-line {
  width: 50%;
  height: 3px;
  background-color: white;
  margin: 3px auto;
}

@media (max-width: 576px) {
  .hr-line {
    height: 2px;
    margin: 2.5px auto;
  }
}
