.pot-with-plant {
  transform: scaleX(-1);
  height: auto;
  width: 550px;
}

.stages-img-div-container {
  position: absolute;
  bottom: 0;
  right: 0;
}

.stages-img-div {
  position: relative;
  height: 850px;
  width: 400px;
  display: flex;
  justify-content: center;
}

.stages-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow-clip-margin: content-box;
  overflow: clip;
  transform: scaleX(-1);
}

.stages-container {
  height: 750px;
  width: "100%";
  background-color: #5e703d;
  margin-top: 100px;
}

.stages-cbutton {
  position: absolute;
  bottom: 4%;
  margin-left: 50px;
}

.stages-other-button {
  display: none;
}

.stages-cbutton:hover,
.stages-other-button:hover {
  background-color: black;
  /* Изменили цвет при наведении на кнопку */
}

.stages-title {
  color: white;
  line-height: 72px;
  font-size: var(--title-font-size);
}

.round-item {
  height: 18px;
  width: 18px;
  border-radius: 9px;
  background-color: #db6630;
}

.vertical-line-container {
  height: 48px;
  width: 18px;
  display: flex;
  justify-content: center;
}

.vertical-line {
  height: 48px;
  width: 1px;
  background-color: #db6630;
}

.stages-item-text {
  position: absolute;
  left: 50px;
  top: 0;
  transform: translate(0, -25%);
  font-size: var(--default-font-size);
  color: white;
  font-weight: 300;
}

.stages-separator {
  margin-top: 80px;
}

@media (max-width: 992px) {
  .round-item {
    height: 16px;
    width: 16px;
    border-radius: 8px;
  }

  .vertical-line-container {
    width: 16px;
    height: 45px;
  }

  .vertical-line {
    height: 45px;
  }

  .stages-img-div {
    height: 650px;
    width: 400px;
    transform: scaleX(-1);
  }

  .stages-img-div-container {
    right: -200px;
    pointer-events: none;
  }

  .stages-item-text {
    z-index: 1;
    width: 300px;
    line-height: 20px;
  }

  .stages-cbutton {
    display: none;
  }

  .stages-other-button {
    display: block;
    margin-top: 50px;
  }

  .stages-title {
    line-height: 50px;
    font-size: 30px;
  }

  .stages-separator {
    margin-top: 50px;
  }

  .stages-container {
    margin-top: 80px;
  }
}

@media (max-width: 768px) {
  .round-item {
    height: 14px;
    width: 14px;
    border-radius: 7px;
  }

  .vertical-line-container {
    width: 14px;
  }

  .stages-title {
    line-height: 35px;
    font-size: 22px;
    z-index: 1;
  }

  .stages-item-text {
    width: 250px;
  }

  .stages-separator {
    margin-top: 40px;
  }

  .stages-img-div-container {
    right: -130px;
  }

  .stages-container {
    height: 690px;
    margin-top: 60px;
  }

  .stages-img-div {
    height: 600px;
    width: 350px;
  }
}

@media (max-width: 576px) {
  .stages-img-div {
    height: 300px;
    width: 150px;
  }

  .stages-img-div-container {
    right: -40px;
  }

  .stages-container {
    height: 550px;
    margin-top: 40px;
  }

  .stages-title {
    line-height: 33px;
    font-size: 21px;
  }

  .stages-separator {
    margin-top: 30px;
  }

  .stages-item-text {
    line-height: 13px;
    font-size: 13px;
    width: 240px;
  }

  .stages-other-button {
    margin-top: 40px;
  }

  .vertical-line {
    height: 30px;
  }

  .vertical-line-container {
    height: 30px;
    width: 12px;
  }

  .round-item {
    height: 12px;
    width: 12px;
  }
}