.hr-separator {
  height: 1px;
  width: 150px;
  background-color: black;
}

@media (max-width: 1400px) {
  .hr-separator {
    width: 130px;
  }
}

@media (max-width: 1200px) {
  .hr-separator {
    width: 100px;
  }
}

@media (max-width: 992px) {
  .hr-separator {
    width: 100px;
  }
}

@media (max-width: 768px) {
  .hr-separator {
    width: 90px;
  }
}

@media (max-width: 576px) {
  .hr-separator {
    width: 70px;
  }
}
